app-grid {
    min-height: 0;
}

.k-grid-header {
    @apply bg-quaternary;
}

.k-tabstrip .k-grid-toolbar {
    @apply rounded-none;
}

// Hide empty kendo-grid-toolbar
// hide the kendo-grid-toolbar if it doesn't have any children that are not kendo-grid-spacer
kendo-grid-toolbar.k-toolbar:not(:has(*:not(kendo-grid-spacer))) {
    @apply hidden;
}
